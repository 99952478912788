<template>
  <TTView>
    <VRow>
      <VCol>
        <SkillForm
          :entity="skill"
          @update:name="skill.name = $event"
          @update:description="skill.description = $event"
          @cancel="handleCancel"
          @submit="handleSubmit"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { DEFAULT_SKILL } from '../../components/competency-matrix/skills/common';
import SkillForm from '../../components/competency-matrix/skills/SkillForm.vue';

export default {
  name: 'SkillEdit',

  components: {
    SkillForm,
  },

  data() {
    return {
      loading: false,
      skill: { ...DEFAULT_SKILL },
    };
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        const { skillId: id } = this.$route.params;
        const data = { id };
        const [skillResponse] = await Promise.all([
          this.$di.api.CompetencyMatrix.SkillsGet(data),
        ]);

        this.skill = skillResponse.skill || { ...DEFAULT_SKILL };
      } catch (err) {
        this.$di.notify.errorHandler(err);
        this.$di.redirect.errorHandler404(err);
      } finally {
        // no-finally
      }
    },

    async handleSubmit() {
      try {
        this.loading = true;
        const { skill } = this;
        if (skill.descriptionHtml) delete skill.descriptionHtml;
        await this.$di.api.CompetencyMatrix.SkillsUpdate({ skill });
        this.$router.go(-1);
        // TODO: показать уведомление
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    handleCancel() {
      this.$router.go(-1);
    },
  },
};
</script>
